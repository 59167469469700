<template>
  <div class="account">
    <div class="page_container">
      <BackComponent :title="$t('myAccount')" goto="/" />
    </div>

    <div class="card_one page_container">
      <router-link to="/profile-update">
        <div class="profile_icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="20"
            viewBox="0 0 21 20"
            fill="none"
          >
            <path
              d="M14.1667 5.83268C14.1667 7.85773 12.5251 9.49935 10.5 9.49935C8.475 9.49935 6.83337 7.85773 6.83337 5.83268C6.83337 3.80764 8.475 2.16602 10.5 2.16602C12.5251 2.16602 14.1667 3.80764 14.1667 5.83268Z"
              fill="#383636"
              stroke="#383636"
            />
            <path
              d="M17.5743 18.2507H3.4258C3.48063 15.1983 6.54262 12.584 10.5 12.584C14.4575 12.584 17.5195 15.1983 17.5743 18.2507Z"
              fill="#383636"
              stroke="#383636"
            />
          </svg>
        </div>
      </router-link>
      <router-link to="/profile-update">
        <p class="id">{{ $t("playerId") }}: {{ myProfile.msisdn }}</p>
      </router-link>
    </div>

    <div class="balance page_container">
      <p>{{ $t("walletBalance") }}</p>
      <div class="price">
        <h3>
          <span>
            {{ isHidden ? "******" : comaFormated(myBalance, lang) }}
            {{ currency }}</span
          >
        </h3>
        <span @click="toggleBalanceVisibility" style="cursor: pointer">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <path
              d="M9.68661 6.31328L6.31328 9.68661C5.87995 9.25328 5.61328 8.65995 5.61328 7.99995C5.61328 6.67995 6.67995 5.61328 7.99995 5.61328C8.65995 5.61328 9.25328 5.87995 9.68661 6.31328Z"
              stroke="#8C8787"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M11.88 3.84633C10.7134 2.96633 9.38002 2.48633 8.00002 2.48633C5.64668 2.48633 3.45335 3.87299 1.92668 6.27299C1.32668 7.21299 1.32668 8.793 1.92668 9.733C2.45335 10.5597 3.06668 11.273 3.73335 11.8463"
              stroke="#8C8787"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M5.61328 13.0204C6.37328 13.3404 7.17995 13.5138 7.99995 13.5138C10.3533 13.5138 12.5466 12.1271 14.0733 9.72711C14.6733 8.78711 14.6733 7.20711 14.0733 6.26711C13.8533 5.92044 13.6133 5.59378 13.3666 5.28711"
              stroke="#8C8787"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M10.34 8.4668C10.1666 9.4068 9.39996 10.1735 8.45996 10.3468"
              stroke="#8C8787"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M6.31337 9.6875L1.33337 14.6675"
              stroke="#8C8787"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M14.6666 1.33398L9.68665 6.31398"
              stroke="#8C8787"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </span>
      </div>
      <p>
        {{ $t("withdrawableBalance") }}:
        <span
          >{{ isHidden ? "******" : comaFormated(widthrawableBalance, lang) }}
          {{ currency }}</span
        >
      </p>
    </div>

    <div class="wallet_actions page_container">
      <router-link id="deposit-profile" to="/deposit">
        <ChopbetButton
          variant="primary"
          :size="'small'"
          @click="handleDepositClick"
          >{{ $t("deposit") }}</ChopbetButton
        >
      </router-link>
      <router-link id="withdraw-profile" to="/withdraw">
        <ChopbetButton
          variant="outline"
          @click="handleDepositClick"
          :size="'small'"
          >{{ $t("withdraw") }}</ChopbetButton
        >
      </router-link>
    </div>

    <section class="profile_links">
      <section>
        <div
          v-for="(link, index) in filteredMenuLinks"
          :key="index"
          class="links"
        >
          <router-link :to="link.route">
            <span :class="link.textClass">{{ link.title }}</span>
            <div class="arrow_box">
              <span
                v-if="link.key === 'edit-profile' && status === null"
                class="verify"
              >
                {{ $t("notVerified") }}
              </span>
              <span
                v-if="link.key === 'edit-profile' && status !== null"
                class="verify2"
              >
                <KycStatus :status="status" :isPreview="true" />
              </span>

              <span v-if="link.key === 'freeBet'" class="freebet_verify">
                {{ balanceText }} {{ $t("freeBet") }}
              </span>
              <span v-if="link.key === 'bonus'" class="bonus">
                {{ myBonusBalance }} {{ currency }}
              </span>
              <span class="arrowRight" v-show="link.key !== 'bonus'">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M5.93994 13.2807L10.2866 8.93404C10.7999 8.4207 10.7999 7.5807 10.2866 7.06737L5.93994 2.7207"
                    stroke="#BAB7B7"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
            </div>
            <div v-if="index !== filteredMenuLinks.length - 1"></div>
          </router-link>
        </div>

        <!-- <router-link to="/logout"> -->
        <div class="links logout">
          <a @click="logout">
            <span>{{ $t("logout") }}</span>
          </a>
        </div>
        <!-- </router-link> -->
      </section>

      <!-- <WalletDeposit />
      <WalletWithdraw /> -->
    </section>
    <span id="open-shareinvite" class="hidden"></span>
  </div>
</template>

<script>
import walletserve from "@/services/walletserve";
import bonusserve from "@/services/bonus";
import instance from "../../services/identity";
// import WalletDeposit from "../../components/wallet/Deposit.vue";
// import WalletWithdraw from "../../components/wallet/Withdraw.vue";
import BackComponent from "../../components/ui/BackComponent.vue";
import ChopbetButton from "../../components/ui/ChopbetButton.vue";
import KycStatus from "../../components/KycStatus.vue";
import bettingserve from "../../services/bettingserve";
import { comaFormated } from "../../utils/formatter";

export default {
  name: "Setting",
  components: {
    // WalletDeposit,
    // WalletWithdraw,
    BackComponent,
    ChopbetButton,
    KycStatus,
  },
  data: function () {
    return {
      currency: process.env.VUE_APP_CURRENCY,
      lang: this.$i18n.locale,
      myProfile: this.getProfile(),
      isHidden: false,
      myBonusBalance: null,
      status: null,
      balance: 0,
      menuLinks: [
        {
          title: this.$t("accountVerification"),
          key: "edit-profile",
          route: "/profile-update",
          textClass: "text-dark",
        },
        // {
        //   title: this.$t("myAccountMenu.idVerification"),
        //   key: "id-verification",
        //   route: "/account-verification",
        //   icon: "/menu/right.webp",
        //   textClass: "text-dark",
        // },
        {
          title: this.$t("myAccountMenu.transactions"),
          route: "/transactions",
          textClass: "text-dark",
        },
        // {
        //   title: this.$t("myAccountMenu.referrals"),
        //   route: "/referral",
        //   textClass: "text-dark",
        // },
        {
          title: this.$t("myAccountMenu.myBets"),
          route: "/my-bets",
          textClass: "text-dark",
        },
        // {
        //   title: this.$t("myAccountMenu.freeBet"),
        //   route: "/free-bet",
        //   icon: "/menu/right.webp",
        //   textClass: "text-dark",
        //   key: "freeBet",
        // },
        {
          title: this.$t("myAccountMenu.bonusBalance"),
          // route: "/bonus-balance",
          route: "",
          textClass: "text-dark",
          key: "bonus",
        },
        {
          title: this.$t("changePassword"),
          route: "/change-password",
          textClass: "text-dark",
        },
        {
          title: this.$t("myAccountMenu.language"),
          route: "/language",
          textClass: "text-dark",
        },
      ],
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        document.title = "My Chopbet.com Account";
        document.description = "Manage Chopbet.com account";
      },
    },
    new_referral_code: function (n) {
      console.log("got new value here " + n);
      this.validateReferralLink();
    },
  },
  created() {
    var profile = this.getProfile();
    var isLoggedIn = profile && profile.a;
    if (isLoggedIn) {
      this.EventBus.$on("mb8:updated", (mb8balance) => {
        this.receivedMB8Balance = mb8balance;
      });
      this.EventBus.$on("bonus:updated", (bonus) => {
        this.receivedBonus = bonus;
      });
    }
  },
  mounted() {
    this.getKycDetails();
    this.fetchMyBonusBalance();
    this.getFreeBalance();
    this.reloadProfile();
    this.$store.dispatch("setCurrentPage", "setting");
    var vm = this;

    vm.myProfile = vm.getProfile();
    if (!vm.myProfile) {
      this.setError("Login", this.$t("pleaseLoginProceed"));
      this.$router.push({ name: "login", params: {} });
      return;
    }
    vm.myProfile = vm.getProfile();
    this.code = vm.myProfile.referral_code;
    this.initShareInviteModal();
  },
  methods: {
    comaFormated,
    async getFreeBalance() {
      try {
        const path = `${process.env.VUE_APP_BASE_FREEBET_URL}/balance`;
        const response = await bettingserve.get(path, {
          headers: {
            "api-key": this.getAuth(),
          },
        });
        const todayDate = new Date();
        const expiryDate = new Date(response.data.expiry_date);
        if (
          response.data.balance !== 0 &&
          expiryDate.getTime() >= todayDate.getTime()
        ) {
          this.balance = response.data.balance;
        }
      } catch (error) {
        if (error.response) {
          if ([401, 400, 428].includes(error.response.status)) {
            this.setError(`${this.$t("sessionExpired")}`);
            this.logout();
          } else {
            this.setError(error.response.data.message);
          }
        } else {
          // this.setError("An error occurred while fetching data. Please try again later.");
        }
      } finally {
        this.balanceLoading = false;
        this.loading = false;
      }
    },

    toggleBalanceVisibility() {
      this.isHidden = !this.isHidden;
    },
    getInitials(firstName, lastName) {
      const firstInitial = firstName ? firstName.charAt(0).toUpperCase() : "";
      const lastInitial = lastName ? lastName.charAt(0).toUpperCase() : "";
      return `${firstInitial}${lastInitial}`;
    },
    fetchMyBonusBalance() {
      var vm = this;
      const path = process.env.VUE_APP_BONUS_BASE_URL + "/bonus/balance";
      bonusserve
        .get(path, {
          headers: {
            "Content-Type": "application/json",
            "api-key": vm.getAuth(),
          },
        })
        .then((response) => {
          this.myBonusBalance = response.data.balance;
        })
        .catch((err) => {
          console.error("Error bonus balance:", err);
        });
    },
    showDeposit() {
      this.depositPage = true;
      this.withdrawalPage = false;
    },
    showWithdrawal() {
      this.withdrawalPage = true;
      this.depositPage = false;
    },

    setAmount: function (currentAmount) {
      this.amount = parseFloat(currentAmount).toFixed(2);
    },

    generateOrRegeneratereferralcode: function () {
      this.reset();
      this.errors = [];

      var p = this.getProfile();
      var ref = p.referral_code;
      if (!p) {
        this.setError("Login", this.$t("pleaseLoginProceed"));
        this.$router.push({ name: "login", params: {} });
        return;
      }

      return ref;
    },

    redeemBonus: function () {
      var vm = this;
      var path = process.env.VUE_APP_BASE_BONUS_URL + "/campaign/redeem";

      var payload = {
        bonus_code: String(this.bonus_code),
      };

      bonusserve
        .post(path, JSON.stringify(payload), {
          headers: {
            "api-key": vm.getAuth(),
          },
        })
        .then((res) => {
          vm.loading = "";
          vm.bonusdata = res.data.data;
          7;
        })
        .catch((err) => {
          vm.loading = "";
          if (err.response) {
            // console.log(JSON.stringify(err.request));
          } else {
            vm.setError(
              `${this.$t("networkError")}`,
              `${this.$t("checkYourNetwork")}`
            );
          }
        });
    },

    deposit: function () {
      this.reset();
      var p = this.getAuth();
      if (!p) {
        this.setError("Login", this.$t("pleaseLoginProceed"));
        this.$router.push({ name: "login", params: {} });
        return;
      }

      if (this.amount < 10) {
        this.setError(
          "Invalid Amount",
          `Enter amount atleast 10 ${this.currency} or above`
        );
        return;
      }

      var vm = this;
      var path = "/deposit/initiate";

      var data = {
        amount: parseInt(this.amount),
      };

      vm.loading = "loading";

      walletserve
        .post(path, JSON.stringify(data), {
          headers: {
            "api-key": vm.getAuth(),
          },
        })
        .then((res) => {
          vm.loading = "";
          var msg = res.data.data;
          vm.setSuccess(msg);
          this.showSuccessModal = true;
          setTimeout(() => {
            this.showSuccessModal = false;
          }, 2000);
        })
        .catch((err) => {
          vm.loading = "";
          if (err.response) {
            if (
              parseInt(err.response.data.status) === 401 ||
              parseInt(err.response.data.status) === 428
            ) {
              vm.setError(`${this.$t("sessionExpired")}`);
              vm.logout();
              return;
            } else {
              vm.setError("Failed", err.response.data.error_message);
            }
          } else if (err.request) {
            vm.setError(
              `${this.$t("networkError")}`,
              `${this.$t("checkYourNetwork")}`
            );
          } else {
            // console.log(JSON.stringify(err));
          }
        });
    },

    jisort: function () {
      this.reset();
      var p = this.getAuth();
      if (!p) {
        this.setError("Login", this.$t("pleaseLoginProceed"));
        this.$router.push({ name: "login", params: {} });
        return;
      }

      var vm = this;
      var path = "/deposit/reconcile";

      var data = {
        transaction_id: this.transaction_id,
      };

      vm.loading = "loading";

      walletserve
        .post(path, JSON.stringify(data), {
          headers: {
            "api-key": vm.getAuth(),
          },
        })
        .then((res) => {
          vm.loading = "";
          var msg = res.data.data;
          vm.setSuccess(msg);
          this.showSuccessModal = true;
          setTimeout(() => {
            this.showSuccessModal = false;
          }, 2000);
        })
        .catch((err) => {
          vm.loading = "";
          if (err.response) {
            if (
              parseInt(err.response.data.status) === 401 ||
              parseInt(err.response.data.status) === 428
            ) {
              vm.setError(`${this.$t("sessionExpired")}`);
              vm.logout();
              return;
            } else {
              vm.setError(err.response.data.error_message);
            }
          } else if (err.request) {
            vm.setError(`${this.$t("checkYourNetwork")}`);
          } else {
            // console.log(JSON.stringify(err));
          }
        });
    },

    withdraw: function () {
      if (this.loading === "loading") {
        return;
      }

      var vm = this;

      setTimeout(function () {
        vm.loading = "";
      }, 5000);

      this.reset();

      var p = this.getAuth();

      if (!p) {
        this.loading = "";
        this.setError("Login", this.$t("pleaseLoginProceed"));
        this.$router.push({ name: "login", params: {} });
        return;
      }

      if (this.withdraw_amount < 50) {
        this.setError(
          "Invalid Amount",
          `Enter amount atleast 50 ${this.currency} or above`
        );
        return;
      }

      var path = "/withdraw";

      var data = {
        amount: parseInt(this.withdraw_amount),
        // msisdn: parseInt(this.msisdn),
      };

      this.loading = "loading";

      walletserve
        .post(path, data, {
          headers: {
            "api-key": vm.getAuth(),
          },
        })
        .then((res) => {
          console.log("Response for Withdraw", res);
          vm.loading = "";
          var msg = res.data.data;
          if (parseInt(res.data.status) === 200) {
            vm.setSuccess("Withdrawal Initiated", msg);
          }
          if (parseInt(res.data.status) === 201) {
            vm.setSuccess("Withdrawal Initiated", msg);
          }
          this.showSuccessModal = true;
          setTimeout(() => {
            this.showSuccessModal = false;
          }, 2000);
        })
        .catch((err) => {
          console.log("Error", err);
          vm.loading = "";
          var msg = err.response.data.error_message;
          if (!vm.errorDisplayed) {
            if (parseInt(err.response)) {
              vm.setError("Failed", msg);
            } else if (
              parseInt(err.response.data.status) === 401 ||
              parseInt(err.response.data.status) === 428
            ) {
              vm.setError(`${this.$t("sessionExpired")}`);
              vm.logout();
            } else {
              vm.setError("Failed", msg);
              // console.log(JSON.stringify(err.response.data.message));
            }
            vm.errorDisplayed = true;
          }
        });
    },

    shareInvite: function () {
      document.getElementById("open-shareinvite").click();
    },
    copyShareBetLink: function () {
      var profile = this.getProfile();
      if (!profile) {
        return;
      }
      var link = "https://dev.chopbet.ci/join?p=" + profile.referral_code;
      this.copyToClipboard(link);
      this.copyText = "Copied";
    },

    getKycDetails() {
      var vm = this;
      var path = "/kycs";

      instance
        .get(path, {
          headers: {
            "api-key": vm.getAuth(),
          },
        })
        .then((res) => {
          vm.loading = "";
          var msg = res.data;
          // vm.setSuccess( msg);
          vm.firstName = msg.first_name;
          vm.lastName = msg.last_name;
          vm.email = msg.email;
          vm.dateOfBirth = msg.date_of_birth;
          this.status = msg.status;
        })
        .catch((err) => {
          vm.loading = "";
          if (err.response) {
            if (
              parseInt(err.response.data.status) === 401 ||
              parseInt(err.response.data.status) === 428
            ) {
              vm.setError(`${this.$t("sessionExpired")}`);
              vm.logout();
              return;
            } else {
              // vm.setError("Failed", err.response.data.error_message);
              return;
            }
          } else {
            console.log(JSON.stringify(err));
          }
        });
    },

    copyCode: function () {
      var link = "accept#" + this.code;
      this.copyToClipboard(link);
      this.copyBookingCode = "Invite Code Copied";
    },

    initShareInviteModal: function () {
      var modal = document.getElementById("shareinvite-modal");

      // Get the button that opens the modal
      var btn = document.getElementById("open-shareinvite");

      // Get the <span> element that closes the modal
      //var span = document.getElementsByClassName("sharebet-close")[0];

      // When the user clicks on the button, open the modal
      btn.onclick = function () {
        modal.style.display = "block";
      };

      // When the user clicks on <span> (x), close the modal
      /*
      span.onclick = function() {
        modal.style.display = "none";
      }
      */

      // When the user clicks anywhere outside of the modal, close it
      window.onclick = function (event) {
        if (event.target == modal) {
          modal.style.display = "none";
        }
      };

      document.addEventListener("click", (e) => {
        if (e.target == document.querySelector("#shareinvite-modal")) {
          modal.style.display = "none";
        }
      });
    },
  },
  computed: {
    filteredMenuLinks() {
      const filteredLinks = this.menuLinks.filter(
        (link) => !(link.key === "freeBet" && this.balance === 0)
      );

      if (this.balanceText === 1) {
        const bonusIndex = filteredLinks.findIndex(
          (link) => link.key === "bonus"
        );

        if (bonusIndex !== -1) {
          filteredLinks.splice(bonusIndex + 1, 0, {
            title: this.$t("myAccountMenu.freeBet"),
            route: "/free-bet",
            textClass: "text-dark",
            key: "freeBet",
          });
        }
      }

      return filteredLinks;
    },
    balanceText() {
      return this.balance === 0 ? 0 : 1;
    },
    bal: function () {
      return this.formatCurrency(this.profile.b1);
    },
    bonus: function () {
      return this.formatCurrency(
        this.profile.balance + this.profile.pending_activation
      );
    },
    myBalance: function () {
      return this.formatCurrency(this.$store.state.walletBalance);
    },
    widthrawableBalance: function () {
      return this.formatCurrency(this.$store.state.widthrawableBalance);
    },
    myBonus: function () {
      return this.$store.state.bonus;
    },
    msg: function () {
      return "Karibu Chopbet.com, Come let's win BIG together.";
    },
    profile: function () {
      return this.myProfile;
    },
    // has_referral_code: function () {
    //   return this.code !== undefined && this.code.length > 3;
    // },
    shouldDisplayTrivia() {
      const now = new Date();
      const startDate = new Date("2023-11-22T12:00:00");
      const endDate = new Date("2023-11-22T15:30:00");

      return now >= startDate && now <= endDate;
    },
  },
};
</script>

<style src="./index.css" scoped></style>
