<template>
  <div>
    <div v-if="status !== null && !isPreview" class="kyc_application">
      <span  class="kyc_label">{{ $t("kycApplication") }}:</span>
      <p :class="statusClass">{{ statusMessage }}</p>
    </div>
    <span v-if="isPreview" :class="statusClass">{{ statusMessage }}</span>
    
  </div>

  </template>
  
  <script>
  export default {
    name: "KycStatus",
    props: {
      status: {
        type: Number,
        required: true,
      },
      isPreview: {
        type: Boolean,
        required: false,
      }
    },
    computed: {
      statusMessage() {
        switch (this.status) {
          case 0:
            return `${this.$t("pending")}`;
          case -1:
            return `${this.$t("rejected")}`;
          case 1:
            return `${this.$t("approved")}`;
          default:
            return "Unknown Status";
        }
      },
      statusClass() {
        switch (this.status) {
          case 0:
            return "kyc_status pending";
          case -1:
            return "kyc_status rejected";
          case 1:
            return "kyc_status approved";
          default:
            return "kyc_status unknown";
        }
      },
    },
  };
  </script>
  
  <style scoped>

  .kyc_application {
  /* background-color: #f9f9f9; */
  /* border: 1px solid #e0e0e0; */
  border-radius: 8px;
  padding: 4px 8px;
  margin: 5px 0;
  max-width: 400px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
}

.kyc_label {
  font-weight: bold;
  font-size: 12px;
  color: var(--text-color);
}

.kyc_status {
  padding: 4px 8px;
  border-radius: 70px;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  letter-spacing: -0.1px;
  margin-top: auto;
  margin-bottom: auto;
}
  
  .kyc_status.pending {
    color: #e67e22;
  background: #fdf3e0;
  }
  
  .kyc_status.rejected {
    color: #cc1717;
    background: var(--Light-Mode-Red-Red-100, #fcf2f2);
  }
  
  .kyc_status.approved {
    color: #28a745;
    background: #e6f9f0;
  }
  
  .kyc_status.unknown {
    color: #6c757d;
  background: #f8f9fa;
  }
  </style>
  